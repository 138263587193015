var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b9f144714e88c1c19f7910e41c48773817d04619"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:  SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  environment: process.env.NEXT_PUBLIC_ENV,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'The operation is insecure.',
    'cancelled',
    "Failed to execute 'Cache' on 'add': Request failed",
    'Illegal invocation',
    'The object is in an invalid state.',
    'Load failed',
    'Failed to fetch',
    'Could not load "marker".',
    'Could not load "map".',
    'Could not load "util".',
    'No coupon found with the code provided',
    'We could not retrieve data from the specified Element. Please make sure the Element you are attempting to use is still mounted.',
    'NetworkError when attempting to fetch resource.',
    'Response is not OK',
    "Can't find variable: google",
    'initMap is not a function',
    'Websocket connection path is not defined',
    'Could not load "controls".',
    "Can't find variable: google",
    'Invalid time value',
    "NetworkError for: /mediapipe/face_mesh_solution_packed_assets.data",
    "Forbidden : https://dresden.vision/mediapipe/face_mesh_solution_packed_assets.data",
    "Cannot set properties of null (setting 'volume')",
    "null is not an object (evaluating 'window.open(e,t).focus')",
    "undefined is not an object (evaluating 'e.getInitialProps')",
    "Member PerformanceObserverInit.entryTypes is required and must be an instance of sequence",
    "Cannot convert undefined or null to object",
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "Failed to execute 'has' on 'CacheStorage': An attempt was made to break through the security policy of the user agent.",
    "Cannot read properties of undefined (reading 'getInitialProps')",
    "NotSupportedError: Failed to register a ServiceWorker for scope ('https://dresden.vision/') with script ('https://dresden.vision/sw.js'): The user denied permission to use Service Worker.",
    "Failed to execute 'open' on 'CacheStorage': An attempt was made to break through the security policy of the user agent.",
    "undefined is not a function (near '...Module['FS_createPreloadedFile']...')",
    "undefined is not an object (evaluating 'Module.dataFileDownloads[url]')",
    "Can't find variable: logMutedMessage",
    "undefined is not an object (evaluating 'f[`${t.identifier}`][`${e}`][`${a}`]')",
    "undefined is not an object (evaluating 'HEAP8.buffer')",
    "NetworkError for: /mediapipe/face_mesh_solution_packed_assets.data",
    "Hydration failed because the initial UI does not match what was rendered on the server.",
    "Cannot read properties of undefined (reading 'meta')",
    'Permission denied to access property "ShadowRoot" on cross-origin object',
    "Script https://dresden.vision/sw.js load failed",
    "Failed to execute 'add' on 'Cache': Request failed",
    "AbortError: The operation was aborted.",
    "RuntimeError: abort(undefined) at Error",
    "undefined is not an object (evaluating 'new Sa.gw')",
    "ResizeObserver loop completed with undelivered notifications.",
    "google is not defined",
    "Can't find variable: hj",
    "No currency found!",
    "Cancel rendering route",
    "Expired token",
    "Cannot read properties of undefined (reading 'sub_category_data')",
    "PD Meter Error [object PromiseRejectionEvent]",
    "Failed to execute 'put' on 'Cache': Quota exceeded.",
    "No error message",
    "QuotaExceededError: Quota exceeded.",
    "SecurityError: Failed to register a ServiceWorker: No URL is associated with the caller's document.",
    "null is not an object (evaluating 't.blacklistC')",
    "Cannot read properties of undefined (reading 'running')",
    "null is not an object (evaluating 'Y.current.volume=1')",
    "Cache.put() encountered a network error",
    "Can't find variable: Animation",
    "PD Meter Error [object PromiseRejectionEvent]",
    "Failed to load static props",
    "hj is not defined",
    "No such file or directory",
    "undefined is not an object (evaluating 'r.meta')",
    "Response is not OK",
    "m is undefined",
    "Cannot read properties of undefined (reading 'name')",
    "e.description.replaceAll is not a function",
    "Error creating WebGL context.",
    "File exists",
    "Request aborted"
  ]
});
